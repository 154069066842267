'use client';

import React from 'react';
import Link from 'next/link';
import { Flex, Result } from 'antd';
import { useParams } from 'next/navigation';
import { useMeMutation } from '@shared/services/auth';
import PageSpin from '@shared/components/web/PageSpin';
import { AppRoutes } from '@shared/constants/appRoutes';
import { ErrorBoundary } from '@shared/ui/error-boundary';
import { useSelector, selectAuthUser } from '@shared/redux';
import { useLayout } from '@app/(mediakit)/@private/useLayout';
import { MediaKitServer } from '@widgets/mediakit/media-kit-server';
import { transformComponents } from '@shared/utils/transform-components';
import { useFetchCategoriesMutation } from '@shared/services/categories';
import { useFetchPublicMediakitMutation } from '@shared/services/mediakit';
import { PosthogIdentifyProvider } from '@shared/providers/posthogIdentifyProvider';

const Public: React.FC = () => {
  const params = useParams<{ username: string; slug: string }>();

  const [fetchCategories, categoriesResponse] = useFetchCategoriesMutation();
  const [fetchPublicMediakit, publicMediakitResponse] =
    useFetchPublicMediakitMutation();

  React.useEffect(() => {
    if (params.username) {
      fetchCategories();
      fetchPublicMediakit(params.username);
    }
  }, [params.username, fetchCategories, fetchPublicMediakit]);

  if (publicMediakitResponse.error) {
    if ('status' in publicMediakitResponse.error) {
      if (publicMediakitResponse.error?.status === 404) {
        return (
          <Result
            title="404"
            status="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Link href={AppRoutes.base}>Back Home</Link>}
          />
        );
      }
    }

    return (
      <Result
        status="500"
        title="Something went wrong"
        extra={<Link href={AppRoutes.base}>Back Home</Link>}
        subTitle="An unexpected error occurred. Please try again later."
      />
    );
  }

  if (!categoriesResponse.isSuccess || !publicMediakitResponse.isSuccess) {
    return (
      <Flex gap={24} vertical>
        <div />
        <PageSpin />
      </Flex>
    );
  }

  const { user, visits, mediakit } = publicMediakitResponse.data.data || {};
  const theme = mediakit.content.theme;
  const categories = (categoriesResponse.data?.data?.categories || []).map(
    ({ id, name }) => ({
      value: id,
      label: name,
    }),
  );

  const { layouts, extraData } = transformComponents(
    mediakit.content.components,
  );

  return (
    <ErrorBoundary
      fallback={
        <Result
          status="500"
          title="Something went wrong"
          extra={<Link href={AppRoutes.base}>Back Home</Link>}
          subTitle="An unexpected error occurred. Please try again later."
        />
      }
    >
      <MediaKitServer
        user={user}
        theme={theme}
        visits={visits}
        layouts={layouts}
        mediakit={mediakit}
        extraData={extraData}
        isAuthenticated={true}
        categories={categories}
      />
    </ErrorBoundary>
  );
};

const Private: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useLayout();

  return children;
};

const SelfMediakitProvider = ({ children }: { children: React.ReactNode }) => {
  const authUser = useSelector(selectAuthUser);
  const params = useParams<{ username: string; slug: string }>();

  return params.username !== authUser?.handle ? (
    <Public />
  ) : (
    <Private>{children}</Private>
  );
};

export default function Layout({ children }: { children: React.ReactNode }) {
  const [me] = useMeMutation();
  const authUser = useSelector(selectAuthUser);

  React.useEffect(() => {
    me();
  }, [me]);

  return !authUser ? (
    <Flex gap={24} vertical>
      <div />
      <PageSpin />
    </Flex>
  ) : (
    <PosthogIdentifyProvider>
      <SelfMediakitProvider>{children}</SelfMediakitProvider>
    </PosthogIdentifyProvider>
  );
}
