import React from 'react';
import { sleep } from '@shared/utils/sleep';
import { useParams } from 'next/navigation';
import { defaultSlug } from '@shared/constants';
import { useDispatch, onUpdateAuthUser } from '@shared/redux';
import { mkitDefaults } from '@shared/constants/mkit-defaults';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { useFetchCountriesMutation } from '@shared/services/countries';
import { useFetchCategoriesMutation } from '@shared/services/categories';
import { useFetchTemplatesMutation } from '@widgets/templates-modal/api';
import { useMeMutation, useOnboardingMutation } from '@shared/services/auth';
import {
  useFetchMediakitMutation,
  useCreateMediakitMutation,
  useFetchMediakitsMutation,
  useFetchComponentsMutation,
  useFetchThemeColorsMutation,
} from '@shared/services/mediakit';

export const useLayout = () => {
  const dispatch = useDispatch();
  const params = useParams<{ username: string; slug: string }>();

  const [me] = useMeMutation();
  const posthog = useLocalPostHog();
  const [create] = useCreateMediakitMutation();
  const [onboarding] = useOnboardingMutation();
  const [getTemplates] = useFetchTemplatesMutation();
  const [fetchMediakit] = useFetchMediakitMutation();
  const [fetchCountries] = useFetchCountriesMutation();
  const [fetchMediakits] = useFetchMediakitsMutation();
  const [fetchComponents] = useFetchComponentsMutation();
  const [fetchCategories] = useFetchCategoriesMutation();
  const [fetchThemeColors] = useFetchThemeColorsMutation();

  React.useEffect(() => {
    async function* fetcher() {
      yield me();
      yield sleep(2500);
      yield me();
      yield sleep(2500);
      yield me();
      yield sleep(2500);
      yield me();
      yield sleep(2500);
      yield me();
    }

    (async () => {
      await Promise.all([
        fetchCountries(),
        fetchCategories(),
        fetchThemeColors(),
      ]).catch(console.error);

      try {
        const resMediakits = await fetchMediakits().unwrap();
        const hasMkit = resMediakits?.data?.mediakits?.length > 0;

        if (hasMkit) {
          await Promise.all([
            fetchComponents(),
            fetchMediakit(params.slug || defaultSlug),
          ]);

          dispatch(onUpdateAuthUser({ fetched: 1, shouldFetch: false }));
        } else {
          const templates = await getTemplates({ default: true }).unwrap();
          const defaultTemplate = templates?.data?.templates?.[0];

          if (defaultTemplate?.id) {
            await create({ template_id: defaultTemplate?.id }).unwrap();

            await onboarding({ step: 'basics.profile' });
          } else {
            await create(mkitDefaults).unwrap();
          }

          await Promise.all([
            fetchComponents(),
            fetchMediakit(params.slug || defaultSlug),
          ]);

          for await (const meRes of fetcher()) {
            try {
              if (typeof meRes === 'object') {
                if ((meRes as any)?.data?.data?.user?.fetched === 1) {
                  break;
                }
              }
            } catch (e) {
              console.error(e);
            }
          }

          dispatch(onUpdateAuthUser({ fetched: 1, shouldFetch: false }));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    me,
    create,
    posthog,
    dispatch,
    onboarding,
    params.slug,
    getTemplates,
    fetchMediakit,
    fetchCountries,
    fetchMediakits,
    fetchCategories,
    fetchComponents,
    fetchThemeColors,
  ]);
};
